import React from "react"
import Helmet from 'react-helmet'
import { graphql } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return <>
    <Helmet>
      <title>{frontmatter.title} | Craft Boxing</title>
      <meta name="description" content={frontmatter.metaDescription} />
    </Helmet>
    <div className="home-page">
      <article className="post">
        {frontmatter.type === "Podcast" && 
   <div className="podcast-embed">
    <iframe title="podcast" width="100%" height="180" frameBorder="no" scrolling="no" seamless src={"https://share.transistor.fm/e/" + frontmatter.podcast + "/"}></iframe>
   </div> }
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </div>
  </>;
}

export const pageQuery = graphql`query ($path: String!) {
  site {
    siteMetadata {
      title
    }
  }
  markdownRemark(frontmatter: {path: {eq: $path}}) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      path
      title
      subheading
      author
      type
      podcast
    }
  }
}
`